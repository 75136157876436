// import React from "react";
import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../components/headers/PageHeader";
import VerticalCard from "../components/cards/VerticalCard";
import TechStack from "../components/tech-stack/TechStack";
import Timeline from "../components/timeline/Timeline";
import ExperienceTimeline from "../components/timeline/ExperienceTimeline";
import VolunteeringTimeline from "../components/timeline/VolunteeringTimeline";
import TwoLineTitle from "../components/titles/TwoLineTitle";

const About = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
  const [tabSelected, setTabSelected] = useState({
    currentTab: 1,
    noTabs: 3,
  });

  const wrapperRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (
          tabSelected.currentTab >= 1 &&
          tabSelected.currentTab < tabSelected.noTabs
        ) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab + 1,
          });
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: 1,
          });
        }
      }
    }

    if (e.keyCode === 37) {
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (
          tabSelected.currentTab > 1 &&
          tabSelected.currentTab <= tabSelected.noTabs
        ) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab - 1,
          });
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.noTabs,
          });
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });
  return (
    <div>
      <PageHeader heading="About Me" />
      <div className="flex flex-col items-center bg-white">
        <VerticalCard
          name="Who I am?"
          image="assests/images/pages/yasith.jpg"
          btnText="My Resume"
          btnLink="assests/pdf/yasith-eranda-cv.pdf"         
          target="_blank"
          des="Welcome to my portfolio! I am Yasith, a dedicated and experienced creative professional currently pursuing the Computer Science and Technology degree program at Uva Wellassa University Sri Lanka. With a rich background in Graphic Designing, UI/UX, Motion Graphic Design, and Web Development, I have brought a unique blend of creative flair and technical proficiency to my work. Over the years, I have honed my skills in translating ideas into visually compelling and user-friendly solutions. I am deeply passionate about the intersection of design and technology, constantly seeking innovative approaches to problem-solving. My commitment towards staying abreast of industry trends and leveraging cutting-edge tools allows me to deliver high-quality and aesthetically pleasing outcomes. In addition to my professional endeavors, I am currently enrolled in the Computer Science and Technology undergraduate program at Uva Wellassa University. This academic foundation complements my practical experience by offering a comprehensive understanding of the technological landscape."
        />
        <TwoLineTitle
          des="From designing to development, these are some skills I have been working on for the past couple of years."
          main="My Tech Stacks"
        />
        <TechStack />
        {/* INFO SECTION */}
        <div className="max-w-[1140px] m-4">
          <section className="w-full" aria-multiselectable="false">
            <ul
              className="flex items-center border-b border-slate-200"
              role="tablist"
              ref={wrapperRef}
            >
              <li className="flex-1" role="presentation ">
                <button
                  className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-[#0052D4] focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                    tabSelected.currentTab === 1
                      ? "border-[#0052D4] stroke-[#0052D4] text-[#0052D4] hover:border-[#0052D4]  hover:text-[#0052D4] focus:border-[#0052D4] focus:stroke-[#0052D4] focus:text-[#0052D4] disabled:border-slate-500"
                      : "justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-[#0052D4] hover:text-[#0052D4] focus:border-[#0052D4] focus:stroke-[#0052D4] focus:text-[#0052D4] disabled:text-slate-500"
                  }`}
                  id="tab-label-1a"
                  role="tab"
                  aria-setsize="3"
                  aria-posinset="1"
                  tabindex={`${tabSelected.currentTab === 1 ? "0" : "-1"}`}
                  aria-controls="tab-panel-1a"
                  aria-selected={`${
                    tabSelected.currentTab === 1 ? "true" : "false"
                  }`}
                  onClick={() =>
                    setTabSelected({ ...tabSelected, currentTab: 1 })
                  }
                >
                  <span>Experience</span>
                </button>
              </li>
              <li className="flex-1" role="presentation ">
                <button
                  className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-[#0052D4] focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                    tabSelected.currentTab === 2
                      ? "border-[#0052D4] stroke-[#0052D4] text-[#0052D4] hover:border-[#0052D4]  hover:text-[#0052D4] focus:border-[#0052D4] focus:stroke-[#0052D4] focus:text-[#0052D4] disabled:border-slate-500"
                      : "justify-self-center "
                  }`}
                  id="tab-label-2a"
                  role="tab"
                  aria-setsize="3"
                  aria-posinset="2"
                  tabindex={`${tabSelected.currentTab === 2 ? "0" : "-1"}`}
                  aria-controls="tab-panel-2a"
                  aria-selected={`${
                    tabSelected.currentTab === 2 ? "true" : "false"
                  }`}
                  onClick={() =>
                    setTabSelected({ ...tabSelected, currentTab: 2 })
                  }
                >
                  <span>Education</span>
                </button>
              </li>
              <li className="flex-1" role="presentation ">
                <button
                  className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-[#0052D4] focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                    tabSelected.currentTab === 3
                      ? "border-[#0052D4] stroke-[#0052D4] text-[#0052D4] hover:border-[#0052D4]  hover:text-[#0052D4] focus:border-[#0052D4] focus:stroke-[#0052D4] focus:text-[#0052D4] disabled:border-slate-500"
                      : "justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-[#0052D4] hover:text-[#0052D4] focus:border-[#0052D4] focus:stroke-[#0052D4] focus:text-[#0052D4] disabled:text-slate-500"
                  }`}
                  id="tab-label-3a"
                  role="tab"
                  aria-setsize="3"
                  aria-posinset="3"
                  tabindex={`${tabSelected.currentTab === 3 ? "0" : "-1"}`}
                  aria-controls="tab-panel-3a"
                  aria-selected={`${
                    tabSelected.currentTab === 3 ? "true" : "false"
                  }`}
                  onClick={() =>
                    setTabSelected({ ...tabSelected, currentTab: 3 })
                  }
                >
                  <span>Volunteering</span>
                </button>
              </li>
            </ul>
            <div className="">
              <div
                className={`px-6 py-4 ${
                  tabSelected.currentTab === 1 ? "" : "hidden"
                }`}
                id="tab-panel-1a"
                aria-hidden={`${
                  tabSelected.currentTab === 1 ? "true" : "false"
                }`}
                role="tabpanel"
                aria-labelledby="tab-label-1a"
                tabindex="-1"
              >
                <ExperienceTimeline />
              </div>
              <div
                className={`px-6 py-4 ${
                  tabSelected.currentTab === 2 ? "" : "hidden"
                }`}
                id="tab-panel-2a"
                aria-hidden={`${
                  tabSelected.currentTab === 2 ? "true" : "false"
                }`}
                role="tabpanel"
                aria-labelledby="tab-label-2a"
                tabindex="-1"
              >
                <Timeline />
              </div>
              <div
                className={`px-6 py-4 ${
                  tabSelected.currentTab === 3 ? "" : "hidden"
                }`}
                id="tab-panel-3a"
                aria-hidden={`${
                  tabSelected.currentTab === 3 ? "true" : "false"
                }`}
                role="tabpanel"
                aria-labelledby="tab-label-3a"
                tabindex="-1"
              >
                <VolunteeringTimeline />
              </div>
            </div>
          </section>
        </div>

        {/* <Timeline /> */}
      </div>
    </div>
  );
};

export default About;
