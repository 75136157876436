import PageHeader from "../components/headers/PageHeader";
import Banner from "../components/banner/Banner";
import WhatsappBanner from "../components/banner/WhatsappBanner";
import PortfolioCardRect from "../components/cards/PortfolioCardRect";
import { React, useEffect } from "react";
import TwoLineTitle from "../components/titles/TwoLineTitle";
import ServiceCard from "../components/cards/ServiceCard";

const WebAndUIUX = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const services = [
    {
      id: 1,
      name: "Multi purpose portfolio",
      image: "assests/images/portfolio/web/multipurpose-portfolio.jpg",
      btnText: "Live Demo",
      readmore: "https://yasith-eranda.web.app/",
    },
    {
      id: 2,
      name: "Official Website | Rotaract UWU",
      image: "assests/images/portfolio/web/racuwu.jpg",
      btnText: "Live Demo",
      readmore: "https://racuwu.com/",
    },
    {
      id: 2,
      name: "General Portfolio",
      image: "assests/images/portfolio/web/general-portfolio.jpg",
      btnText: "Live Demo",
      readmore: "https://cv-yasith.netlify.app/",
    },
    {
      id: 3,
      name: "Official Website | CST LAN Challenge",
      image: "assests/images/portfolio/web/cst-lan.jpg",
      btnText: "Live Demo",
      readmore: "https://cstlanchallenge.online/",
    },
    {
      id: 4,
      name: "Auction Site",
      image: "assests/images/university-projects/bidwiz-1.png",
      btnText: "Live Demo",
      readmore: "https://youtu.be/UpIVXFxYg38",
    },
    {
      id: 5,
      name: "Auction Site",
      image: "assests/images/university-projects/bidwiz-2.jpg",
      btnText: "Live Demo",
      readmore:
        "https://youtu.be/a26_0VZ18S8",
    },
    {
      id: 6,
      name: "Official Website | Rotaract UWU",
      image: "assests/images/portfolio/web/rac-uwu.jpg",
      btnText: "Live Demo",
      readmore: "https://racuwu.com/",
    },
    {
      id: 7,
      name: "University Website",
      image: "assests/images/portfolio/web/western-university.jpg",
      btnText: "Live Demo",
      readmore: "https://western-university.onrender.com/",
    },
    {
      id: 8,
      name: "Online BoQ Platform",
      image: "assests/images/portfolio/web/boq-master.jpg",
      btnText: "Live Demo",
      readmore: "https://boq-master.web.app/",
    },
    {
      id: 9,
      name: "App Launching Website",
      image: "assests/images/portfolio/web/lockit-web.jpg",
      btnText: "Live Demo",
      readmore: "https://lockit-nch3.onrender.com/",
    },
    // {
    //   id: 10,
    //   name: "Company Website",
    //   image: "assests/images/portfolio/web/boq-master.jpg",
    //   btnText: "Live Demo",
    //   readmore: "https://texyto-client.web.app/",
    // },
  ];

  const uiux = [
    {
      id: 1,
      name: "Smart Security App",
      image: "assests/images/portfolio/uiux/lockit-m-ui.jpg",
      btnText: "Live Demo",
      readmore:
        "https://www.figma.com/proto/QpSHAbXvwyoT33KRK8QX3W/lockit-mobile-app?page-id=0%3A1&type=design&node-id=3-95&viewport=368%2C76%2C0.2&t=mLk7r991OODmKRLl-1&scaling=scale-down&mode=design",
    },
    {
      id: 2,
      name: "Auction Site | BidWiz 2.0",
      image: "assests/images/portfolio/uiux/bidwiz-ui.jpg",
      btnText: "Live Demo",
      readmore:
        "https://www.figma.com/file/nqkGDl3qmf7NwdFWVF0bq0/BidWiz-2.0?type=design&node-id=1%3A2&mode=design&t=mkpI1ivXboFlqNiJ-1",
    },
    {
      id: 3,
      name: "Calculator App",
      image: "assests/images/portfolio/uiux/cal-1-1.jpg",
      btnText: "Live Demo",
      readmore: "https://www.figma.com/file/5gD7p5YLk0BKYzaeb7IL77/Calculator?type=design&node-id=0%3A1&mode=design&t=wcorGSeVycVgsYre-1",
    },
    {
      id: 4,
      name: "App Launching Website",
      image: "assests/images/portfolio/uiux/lockit-uiux.jpg",
      btnText: "Live Demo",
      readmore: "https://www.figma.com/file/ThbrYxlSDaR8MKnrUtSdzh/Lockit-Web?type=design&node-id=1%3A11&mode=design&t=3ACp0dQvi3I1A6la-1",
    },
  ];
  return (
    <div>
      <PageHeader heading="Web & UI/UX Design" />{" "}
      <div className="flex justify-center pt-8">
        <Banner
          url="contact"
          text="Get More Details"
          btn="Contact Me"
          subtext="I’m always open to discussing product design work or partnerships."
        />
      </div>
      <TwoLineTitle
        des="From designing to development, these are some skills I have been working on for the past couple of years."
        main="Web Deverlopment"
      />
      <div className="flex justify-center">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-8 max-w-[1140px] 2xl:max-w-[1140px] justify-center text-justify">
          {services.map((service, i) => (
            <ServiceCard
              key={i}
              name={service.name}
              tech={service.tech}
              // des={service.des}
              image={service.image}
              // url={service.readmore}
              title={service.title}
              btnText={service.btnText}
              readmore={service.readmore}
              target="_blank"
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center py-8">
        <WhatsappBanner />
      </div>
      <TwoLineTitle
        des="From designing to development, these are some skills I have been working on for the past couple of years."
        main="UI/UX Design"
      />
      <div className="flex justify-center mb-8">
        <div className="grid md:grid-cols-2 w-full lg:grid-cols-3 2xl:grid-cols-3 gap-8 max-w-[1140px] 2xl:max-w-[1140px] justify-center text-justify">
          {uiux.map((uiux, i) => (
            <ServiceCard
              key={i}
              name={uiux.name}
              tech={uiux.tech}
              // des={uiux.des}
              image={uiux.image}
              // url={uiux.readmore}
              title={uiux.title}
              btnText={uiux.btnText}
              readmore={uiux.readmore}
              target="_blank"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebAndUIUX;
